import React from 'react'
import SbEditable from 'storyblok-react'
import Layout from '../layout'
import { ThemeProvider, makeStyles } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import { Box, CssBaseline } from '@material-ui/core'
import { Img } from '@system'
import ACC_THEME from '../../themes'
import get from 'lodash/get'
import PageContainer from '@system/page-container'
import { imgMaxWidths } from '@variables/global-variables'

const useStyles = makeStyles({
  backgroundHeaderImage: {
    width: '100%',
    height: '66vh',
    objectPosition: 'top',
    maxHeight: '600px',
    '& img': {
      objectFit: 'cover',
    },
  },
  body: {
    marginTop: '-180px',
    position: 'relative',
  },
})

const EventsIndex = (props) => {
  const { headerImage, body, filters, metaData, noResultsCopy, noEventsCopy } =
    props.blok
  const classes = useStyles()

  const backgroundHeaderImage = get(headerImage, '[0].image')

  return (
    <Layout metaData={metaData}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <SbEditable content={props.blok}>
          <Box>
            <Box id="header-image">
              {!!backgroundHeaderImage && (
                <Img
                  className={classes.backgroundHeaderImage}
                  src={backgroundHeaderImage}
                  maxWidth={imgMaxWidths.headerImage}
                />
              )}
            </Box>
            <PageContainer>
              <Box className={classes.body}>
                {!!body && renderBloks(body, { isEventCardVisible: true })}
                {!!filters &&
                  renderBloks(filters, {
                    isEventsModule: true,
                    noResultsCopy: noResultsCopy,
                    noEventsCopy: noEventsCopy,
                  })}
              </Box>
            </PageContainer>
          </Box>
        </SbEditable>
      </ThemeProvider>
    </Layout>
  )
}

export default EventsIndex
